import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { SectionWrapper, SectionText, H2 } from 'styles/Typography.styled';

export const StyledSectionWrapper = styled(SectionWrapper)`
    padding: 160px 0 0;
    display: flex;

    ${mediaQueries.md} {
        padding: 56px 16px;
    }
    ${mediaQueries.sm} {
        flex-direction: column;
    }
`;

export const LeftWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 100px;
    ${mediaQueries.sm} {
        padding-right: 0;
    }
`;

export const RightWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    ${mediaQueries.sm} {
        order: -1;
        flex-direction: column;
    }
`;

export const Title = styled(H2)`
    ${mediaQueries.sm} {
        padding-bottom: 24px;
    }
`;

export const MobileTitle = styled(H2)`
    display: none;
    ${mediaQueries.sm} {
        display: block;
    }
`;

export const TextRight = styled(SectionText)(
    ({ theme: { fontWeight } }) =>
        css`
            max-width: 470px;
            padding-top: 24px;
            padding-bottom: 40px;
            font-weight: ${fontWeight.medium};
            display: flex;
            ${mediaQueries.sm} {
                max-width: 100%;
                padding-bottom: 32px;
            }
        `,
);

export const TextLeft = styled(SectionText)(
    ({ theme: { fontWeight } }) =>
        css`
            max-width: 466px;
            padding-top: 72px;
            font-weight: ${fontWeight.medium};
            display: flex;
            ${mediaQueries.sm} {
                max-width: 100%;
                padding-top: 24px;
                padding-bottom: 32px;
            }
        `,
);

export const Image = styled.img`
    max-width: 100%;
    ${mediaQueries.sm} {
        display: none;
    }
`;

export const ImageMobile = styled.img`
    display: none;

    ${mediaQueries.sm} {
        display: block;
    }
    ${mediaQueries.xs} {
        max-width: 100%;
    }
`;

export const InfoBox = styled.div`
    max-width: 470px;
    padding: 0 30px 56px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    ${mediaQueries.sm} {
        max-width: 1;
        margin-top: 40px;
    }

    ${mediaQueries.xs} {
        padding-bottom: 0;
        gap: 32px 0;
        flex-wrap: wrap;
        margin: 40px 0 40px;
    }
`;
